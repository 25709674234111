import React, { useState, useEffect } from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import axios from 'axios';
import * as qs from "query-string"

import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import CalendarImg from "../../assests/images/calendar.png";
import { Link } from "gatsby"
import SEO from "../../components/seo"
//import { Calendar, momentLocalizer } from "react-big-calendar"

import {config} from '../../components/Common/constant';
import dateFormat from 'dateformat';
//const localizer = momentLocalizer(moment)
import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";
import { PropertyDateTime } from "../../queries/api_links";

import { useContainerContext } from "../../store/ContainerContext";

import Property_Launch_DateTime from  "../../components/modules/property_date_time";

function PropertyLaunchDateTime(props){

    var property_date_json = {
      "Launches": [
        {
          "PhotographyAppointments": [
            {
              "PhotographyStart": "2021-04-27T08:00:00.000Z",
              "PhotographyEnd": "2021-04-27T08:30:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            },
            {
              "PhotographyStart": "2021-04-27T08:30:00.000Z",
              "PhotographyEnd": "2021-04-27T08:00:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            },
            {
              "PhotographyStart": "2021-04-27T08:00:00.000Z",
              "PhotographyEnd": "2021-04-27T08:30:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            }
          ],
          "LaunchStart": "2021-04-27T08:30:00.000Z",
          "LaunchEnd": "2021-04-27T08:00:00.000Z",
          "CSM": "alex.renardson@prestonbaker.co.uk"
        }
      ]
    
    }

    const context = useContainerContext();
    const { property_data, property_date_time, property_launch_date_time } = context.state;
    const { PropertyDateTimeDispatch, PropertyLaunchDateTimeDispatch } = context.actions;
    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    const [ article_data, setArticleData ] = useState('');
    const [ propertyDateTime, setPropertyDateTime ] = useState([]);
    const { loading, error, data } = GET_ARTICLE('property-launch-date-and-time');
    const calendarComponentRef = React.createRef();

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);
    

    useEffect(() => {
      if(property_data && property_data.appointment){
        const axiosOptions_URL = {
          url: '/.netlify/functions/server/property-date-time',
          //url: '/server/property-date-time',
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify({id:property_data.appointment.Id}),
        }

        const PropDateTime = axios(axiosOptions_URL).then((result) => {
          //console.log("result", result);
          if(result.status === 200){
            PropertyDateTimeDispatch(result.data);
            //PropertyDateTimeDispatch(property_date_json);
          } else{
            //PropertyDateTimeDispatch(property_date_json);
          }
        }).catch((err) => {
          //PropertyDateTimeDispatch(property_date_json);
          console.log("err", err)
        })
      }    
      //PropertyDateTimeDispatch(property_date_json);
    }, [property_data]);

    useEffect(() => {
      var calendarEvents = [];
      property_date_time.Launches && property_date_time.Launches.length > 0 && property_date_time.Launches.map((item,index) => {
          calendarEvents.push({title: item.CSM, date: item.LaunchStart, start: item.LaunchStart, description:'Lorem ipsum dolor <b>48% quicker</b> ipsum dolor sit amet, consectetur adipiscing <b>4 days less</b> lorem ipsum dolor sit.', PhotographyAppointments: item.PhotographyAppointments, LaunchEnd: item.LaunchEnd, LaunchStart: item.LaunchStart, PMD:item.CSM})
      })
      setPropertyDateTime(calendarEvents);
    }, [property_date_time]);


    return <>
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

    <div className="pre-wrapper">
      
      <Header />
    
      <Property_Launch_DateTime propertyDateTime={propertyDateTime} />
      
      <NavLink tab_name="Schedule" prevLink={config.custom_marketing} nextLink={property_data && parseInt(property_data.price_expectancy) > 200000 ? config.custom_plan : config.custom_teams} />

    </div>
</>
}

export default PropertyLaunchDateTime
